<template>
  <div class="generate-avatar">
    <Button
      text="Generate image"
      type="white"
      :loading="loading"
      @click="generateAvatar"
    />
    <div v-if="options.length" class="generate-avatar-options">
      <p class="generate-avatar-options-header">
        Generated images
        <Button size="xs" icon="close" type="grey" @click="clear" />
      </p>
      <div class="generate-avatar-options-list">
        <img
          v-for="option in options"
          :key="option.url"
          :src="option.url"
          alt=""
          class="generate-avatar-options-list-item"
          @click="() => selectAvatar(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Button from './Button.vue'

export default {
  name: 'GenerateAvatar',
  components: { Button },
  data: () => ({
    loading: false,
    options: []
  }),
  methods: {
    ...mapActions([
      'generateOfferingImage',
      'generateReferenceImage',
      'generateInspirationImage'
    ]),
    async generateAvatar() {
      try {
        this.loading = true
        this.$emit('generateLoading', true)
        this.generateLoading = true
        const generateFunction = {
          offerings: this.generateOfferingImage,
          references: this.generateReferenceImage,
          inspirations: this.generateInspirationImage
        }[this.$route.params.ori_type]
        const { urls } = await generateFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.$route.params.ori_id,
          amount: 4
        })
        const images = await Promise.all(
          urls.map(url =>
            fetch(url)
              .then(i => i.blob())
              .then(
                blobFile =>
                  new File(
                    [blobFile],
                    `avatar.${blobFile.type.split('/').slice(-1)[0]}`,
                    { type: blobFile.type }
                  )
              )
          )
        )
        this.options = images.map((image, index) => ({
          url: urls[index],
          image
        }))
      } catch (e) {
        this.$console.debug('Error generating avatar', e)
        this.$toast.error(e, 'generating avatar')
      } finally {
        this.loading = false
      }
    },
    selectAvatar(avatar) {
      this.$emit('input', avatar.url, avatar.image)
      this.clear()
    },
    clear() {
      this.options = []
    }
  }
}
</script>

<style lang="scss" scoped>
.generate-avatar {
  position: relative;

  &-options {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    width: min(25rem, 90vw);
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    z-index: 11;

    &-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      font-weight: 600;
      font-size: 1.15rem;
    }

    &-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      padding: 1rem;

      &-item {
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 4px;
        border: 1px solid rgba(#000, 0.08);
        cursor: pointer;

        &:hover {
          border: 1px solid rgba(#000, 0.16);
        }
      }
    }
  }
}
</style>
