var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "generate-avatar" },
    [
      _c("Button", {
        attrs: { text: "Generate image", type: "white", loading: _vm.loading },
        on: { click: _vm.generateAvatar }
      }),
      _vm.options.length
        ? _c("div", { staticClass: "generate-avatar-options" }, [
            _c(
              "p",
              { staticClass: "generate-avatar-options-header" },
              [
                _vm._v(" Generated images "),
                _c("Button", {
                  attrs: { size: "xs", icon: "close", type: "grey" },
                  on: { click: _vm.clear }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "generate-avatar-options-list" },
              _vm._l(_vm.options, function(option) {
                return _c("img", {
                  key: option.url,
                  staticClass: "generate-avatar-options-list-item",
                  attrs: { src: option.url, alt: "" },
                  on: {
                    click: function() {
                      return _vm.selectAvatar(option)
                    }
                  }
                })
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }